const defaultState = {
  userName: "",
  email: "",
  syncToken: "",
  syncServer: "https://unternehmen.online",
  firstLaunchDone: false,
  slotsSynced : false,
  teamSynced : false,
  currentGroup : false,
  currentCalendarWeek : 1 ,
  currentLazyFrom: 0,
  currentLazyTo: 0,
  betaEnabled : false
};

const UserReducer = (state = defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case "UPDATE_USER_PROFILE":
        newState[action.payload.fieldName] = action.payload.fieldValue;
        if(action.payload.fieldName == "email"){
         newState["userName"] = action.payload.fieldValue.split("@")[0];
        }
        return newState;

    case "SET_CURRENT_CALENDAR_WEEK":
      newState["currentCalendarWeek"] = action.payload;
      return newState;

    case "SET_CURRENT_GROUP":
      newState["currentGroup"] = action.payload;
      return newState;

    case "ENABLE_BETA":
      newState["betaEnabled"] = true;
      return newState;

    case "DISABLE_BETA":
      newState["betaEnabled"] = false;
      return newState;

    case "FIRST_LAUNCH_DONE":
      newState["firstLaunchDone"] = true;

    case "SET_NEW_LAZY_TIMESPAN":
      newState["currentLazyFrom"] = action.payload.from;
      newState["currentLazyTo"] = action.payload.to;


    default:
      return newState;
  }
};

export default UserReducer;
