import React from "react";
import Gravatar from "react-gravatar";
import { useSelector, useDispatch } from "react-redux";

import stylelist from "stylelist";
import useSlotStats from "../../hooks/useSlotStats";
import { useEffect } from "react";

import { set_sync_status } from "../../actions/Sync";

import TeamMemberInfoCard from "./TeamMemberInfoCard";

const TeamManagement = (props) => {

    const TeamSettings = useSelector((state) => state.Team);
    const UserSettings = useSelector((state) => state.User);
    const dispatch = useDispatch();

    const slotStats = useSlotStats();

    const init = useEffect(() => {
        if (UserSettings.slotsSynced == false || UserSettings.teamSynced == false) {
            dispatch(set_sync_status("asap_full"));
        }
    }, []);


    return <>
        <div className="container mt-5">

            <h1 className="mb-3">Team-Übersicht</h1>

            {Object.values(TeamSettings).length < 1 &&
                <h3>(Wir synchronisieren gerade noch etwas)</h3>
            }

            <div className="row">

                <h3 className="mt-3">Geschäftsleitung</h3>
                {Object.values(TeamSettings).filter(element => element.group == "Geschäftsleitung").map((TeamMember) => {
                    return <>
                        {(TeamMember.status == "active" && TeamMember.group == "Geschäftsleitung") &&
                            <div key={TeamMember.userName} className="col-12 col-md-6  col-xxl-4 mb-4">
                                <a href={"mailto:" + TeamMember.userName + "@unternehmen.online"}>
                                    <TeamMemberInfoCard TeamMember={TeamMember} />
                                </a>
                            </div>
                        }
                    </>
                })}

                <h3 className="mt-3">Team 1</h3>
                {Object.values(TeamSettings).filter(element => element.group == "Team 1").map((TeamMember) => {
                    return <>
                        {(TeamMember.status == "active" && TeamMember.group == "Team 1") &&
                            <div key={TeamMember.userName} className="col-12 col-md-6  col-xxl-4 mb-4">
                                <a href={"mailto:" + TeamMember.userName + "@unternehmen.online"}>
                                    <TeamMemberInfoCard TeamMember={TeamMember} />
                                </a>
                            </div>
                        }
                    </>
                })}

                {Object.values(TeamSettings).filter(element => element.group == "Team 1").map((TeamMember) => {
                    return <>
                        {(TeamMember.status == "inactive" && TeamMember.group == "Team 1") &&
                            <div key={TeamMember.userName} className="col-12 col-md-6  col-xxl-4 mb-4 opacity-25">
                                <TeamMemberInfoCard TeamMember={TeamMember} />
                            </div>
                        }
                    </>
                })}


                <h3 className="mt-3">Team 2</h3>
                {Object.values(TeamSettings).filter(element => element.group == "Team 2").map((TeamMember) => {
                    return <>
                        {(TeamMember.status == "active" && TeamMember.group == "Team 2") &&
                            <div key={TeamMember.userName} className="col-12 col-md-6 col-xxl-4 mb-4">
                                <a href={"mailto:" + TeamMember.userName + "@unternehmen.online"}>
                                    <TeamMemberInfoCard TeamMember={TeamMember} />
                                </a>
                            </div>
                        }
                    </>
                })}

                {Object.values(TeamSettings).filter(element => element.group == "Team 2").map((TeamMember) => {
                    return <>
                        {(TeamMember.status == "inactive" && TeamMember.group == "Team 2") &&
                            <div key={TeamMember.userName} className="col-12 col-md-6 col-xxl-4 mb-4 opacity-25">
                                <TeamMemberInfoCard TeamMember={TeamMember} />

                            </div>
                        }
                    </>
                })}

                <h3 className="mt-3">UX</h3>
                {Object.values(TeamSettings).filter(element => element.group == "UX").map((TeamMember) => {
                    return <>
                        {(TeamMember.status == "active" && TeamMember.group == "UX") &&
                            <div key={TeamMember.userName} className="col-12 col-md-6 col-xxl-4 mb-4">
                                <a href={"mailto:" + TeamMember.userName + "@unternehmen.online"}>
                                    <TeamMemberInfoCard TeamMember={TeamMember} />
                                </a>
                            </div>
                        }
                    </>
                })}

                {Object.values(TeamSettings).filter(element => element.group == "UX").map((TeamMember) => {
                    return <>
                        {(TeamMember.status == "inactive" && TeamMember.group == "UX") &&
                            <div key={TeamMember.userName} className="col-12 col-md-6 col-xxl-4 mb-4 opacity-25">
                                <TeamMemberInfoCard TeamMember={TeamMember} />

                            </div>
                        }
                    </>
                })}


            </div>
            <br /><br /><br />
        </div>
    </>
}

export default TeamManagement