import moment from "moment";
import React, { useEffect, useState } from "react";
import Gravatar from "react-gravatar";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import stylelist from "stylelist";
import { enable_sync, set_sync_status } from "../../actions/Sync";
import { set_current_calendar_week, set_current_group, set_new_lazy_timespan } from "../../actions/User";
import useSlots from "../../hooks/useSlots";
import { getCalendarWeekForDate, getFriday, getMonday } from "../../libs/CalendarWeek";
import { copy, getDayName, getSlotName } from "../../libs/SlotUtils";
import TeamMemberInfoCard from "../Team/TeamMemberInfoCard";
import SharkIf from "../Util/IfCondition";
import SlotTitle from "./SlotTitle";
import { getSlotDate } from "../../libs/SlotUtils";

const SlotCalendar = (props) => {
	// React Hooks
	const Team         = useSelector((state) => state.Team);
	const Slots        =                       useSlots();
	const UserSettings = useSelector((state) => state.User);
	const dispatch     =                       useDispatch();

	// States
	const [showFrom             , setShowFrom]              = useState(new Date("2021-01-25"));
	const [showTo               , setShowTo]                = useState(new Date("2021-01-31"));
	const [matrix               , setMatrix]                = useState([]);
	const [displayedCalendarWeek, setDisplayedCalendarWeek] = useState(9);
	const [clipboardSlot        , setClipboardSlot]         = useState(false);
	const [clipboardMode        , setClipboardMode]         = useState("none");
	const [showAnimation        , setShowAnimation]         = useState(true);
	const [currentGroup         , setCurrentGroup]          = useState("");
	const [currentFilterWord    , setCurrentFilterWord]     = useState("");
	const [displayGratavar      , setDisplayGravatar]       = useState(false);
	const [stealthMode          , setStealthMode]           = useState(false);
	const [controlMode          , setControlMode]           = useState(false);
	const [teamAutoSelectDone   , setTeamAutoSelectdone]    = useState(false);
	const [oofUserThisWeek      , setOofUserThisWeek]       = useState([]);
	const [showDisabledMembers  , setShowDisabledMembers]   = useState(false);
	const [archiveThresholdDate , setArchiveThresholdDate]  = useState(new Date("01.01.1970"));

	const [slotMergeCandidates, setSlotMergeCandidates]		= useState([]);
	const [slotMergeEnabled, setSlotMergeEnabled]			= useState(true);

	// Initialize the component
	const init = useEffect(() => {
		// If slots are not synced on entry, do so now
		if (UserSettings.slotsSynced == false || UserSettings.teamSynced == false) {
			dispatch(set_sync_status("asap_full"));
		}

		// Fade in the gravatar-images a little later
		setTimeout(() => {
			setDisplayGravatar(true);
		}, 800);

		// open the current calendar week
		jumpToCurrentWeek();

	}, []);

	// Change Team default view at start
	const onStartUpdateTeamView = useEffect(() => {
		if (typeof Team[UserSettings["userName"]] != "undefined" && !teamAutoSelectDone) {
			let userObject = Team[UserSettings["userName"]];
			if (userObject.group == "Team 1" || userObject.group == "Team 2") {
				dispatch(set_current_group(userObject.group));
			}

			setTeamAutoSelectdone(true);
		}
	}, [Team]);

	// Jump to the next or previous week
	const nextOrPrevWeek = (nextOrPrev) => {

		let newWeek;
		
		if (nextOrPrev == "next") {
			newWeek = moment(UserSettings["currentCalendarWeek"]).add("7", "days");
		}

		if (nextOrPrev == "prev") {
			newWeek = moment(UserSettings["currentCalendarWeek"]).subtract("7", "days");
		}

		dispatch(set_current_calendar_week(newWeek));
		dispatch(set_new_lazy_timespan(
			getSlotDate(new Date(getMonday(newWeek))), 
			getSlotDate(new Date(getFriday(newWeek)))));
	};

	const jumpToCurrentWeek = () => {
		dispatch(set_current_calendar_week(moment()));
		dispatch(set_new_lazy_timespan(getSlotDate(new Date(getMonday(new Date()))), getSlotDate(new Date(getFriday(new Date())))));

	};

	const updateMergeCandidates = () => {
		let loop = new Date(moment(showFrom).subtract("7", "days"));
		var newDate = loop.setDate(loop.getDate());
		let counter = 0;
		let newCandidates = [];

		while (loop <= new Date(moment(showTo).add("7", "days"))) {
			loop = new Date(newDate);
			counter++;

			Object.values(Team).map(member => {
				if(Slots.exists(getSlotName(loop, member.userName, 1)) && Slots.exists(getSlotName(loop, member.userName, 2))){
					if ( Slots.getBySlotId(getSlotName(loop, member.userName, 1)).content.slotTitle == Slots.getBySlotId(getSlotName(loop, member.userName, 2)).content.slotTitle){
						newCandidates.push(getSlotName(loop, member.userName, 1));
					}
				}
			});

			newDate = loop.setDate(loop.getDate() + 1);
		}

		setSlotMergeCandidates(newCandidates);
	}

	useEffect(()=>{
		updateMergeCandidates();
	}, [showFrom, showTo, Team, clipboardSlot, currentGroup, currentFilterWord, stealthMode, controlMode, oofUserThisWeek, showDisabledMembers]);
	


	// When the CalendarWeek was changed (via redux)
	const onCalendarWeekChange = useEffect(() => {
		setShowFrom(new Date(getMonday(UserSettings["currentCalendarWeek"])));
		setShowTo(new Date(getFriday(UserSettings["currentCalendarWeek"])));
		setDisplayedCalendarWeek(getCalendarWeekForDate(UserSettings["currentCalendarWeek"]));
		
		let thresholdDate = new Date();
		thresholdDate.setDate(thresholdDate.getDate() - 14);
		setArchiveThresholdDate(thresholdDate);

	}, [UserSettings["currentCalendarWeek"]]);

	const recalculatePersonsAvailabilityThisWeek = useEffect(() => {
		let loop = new Date(showFrom);
		let newDate = loop.setDate(loop.getDate());
		let memberCount = [];

		while (loop <= showTo) {
			loop = new Date(newDate);
			Object.values(Team).map((teamMember) => {
				if (typeof memberCount[teamMember.userName] == "undefined") {
					memberCount[teamMember.userName] = 0;
				}

				let slotId1 = getSlotName(loop, teamMember.userName, "1");
				let slotId2 = getSlotName(loop, teamMember.userName, "2");

				if (Slots.exists(slotId1)) {
					if (Slots.getBySlotId(slotId1).content.slotType == "holiday" || Slots.getBySlotId(slotId1).content.slotType == "school" || Slots.getBySlotId(slotId1).content.slotType == "sick" ) {
						memberCount[teamMember.userName]++;
					}
				}

				if (Slots.exists(slotId2)) {
					if (Slots.getBySlotId(slotId2).content.slotType == "holiday" || Slots.getBySlotId(slotId2).content.slotType == "school" || Slots.getBySlotId(slotId2).content.slotType == "sick" ) {
						memberCount[teamMember.userName]++;
					}
				}
			});

			newDate = loop.setDate(loop.getDate() + 1);
			setOofUserThisWeek(memberCount);
		}
	}, [showFrom, Team]);

	// Clipboard-Handler (Handled with a react state)
	const addToClipboard = (slotId, clipboardMode) => {
		setClipboardSlot(slotId);
		setClipboardMode(clipboardMode);
	};

	// This will be removed in future versions
	const getNumberWithLeadingZero = (number) => {
		if (number < 10) {
			return "0" + number;
		} else {
			return number;
		}
	};

	const handleKeypress = (e, type) => {
		if (type == "down" && e.key == "Control") {
			setControlMode(true);
		}

		if (type == "up" && e.key == "Control") {
			setControlMode(false);
			setClipboardMode("none");
			setClipboardSlot(false);
		}
	};

	// Renders the matrix if something changes
	const renderMatrix = useEffect(() => {
		let newMatrix = [];
		var loop = new Date(showFrom);
		var newDate = loop.setDate(loop.getDate());
		let counter = 0;
		let userCounter = 0;

		{
			/* First column: render all team-members */
		}
		newMatrix.push(
			<React.Fragment key={"user-col"}>
				<div className={stylelist("col px-0 pb-3 text-dark")}>
					<div className="row">
						<div className="slot-header-odd pb-0 mb-3 pb-3 slot-header"></div>

						{/* Now iterate through each user */}
						{Object.values(Team)
							.filter((Member) => Member.hasSlots)
							.filter((Member) => ((Member.status == "active" && !showDisabledMembers) || showDisabledMembers))
							.filter((Member) => Member.group == UserSettings["currentGroup"] || UserSettings["currentGroup"] == "")
							.map((teamMember) => {
								userCounter++;
								return (
									<React.Fragment key={teamMember.email}>
										<SharkIf animate={false} show={true} animation="fade" animationDelay={userCounter * 80}>
											<div className={stylelist("slotfield-full d-flex justify-content-around", [teamMember.status == "inactive", "slot-muted"], [oofUserThisWeek[teamMember.userName] == 10, "slot-muted"])}>
												{/* Display the Member-Card on hover */}
												<Popup trigger={(open) => <>
													<span className="username cursor-pointer">{teamMember.realName} {teamMember.status == "inactive" && <><br />(Inaktiv)</>}
														<br />
														<a target="_BLANK" href={"https://teams.microsoft.com/l/chat/0/0?users=" + teamMember.email }><i className="bi bi-chat-dots h6 text-secondary d-inline-block pt-1 ps-1 pe-3"></i></a> &nbsp;
													    <a target="_BLANK" href={"mailto:" + teamMember.email }><i className="bi bi-envelope-at h6 text-secondary d-inline-block pt-1 ps-1 pl-3"></i></a>
													</span>
											
												
												</> } position="right center" closeOnDocumentClick on={["hover"]}>
													<TeamMemberInfoCard TeamMember={teamMember} />
												</Popup>

												<Gravatar default="mp" className={stylelist("avatar", [!displayGratavar, "opacity-0"])} email={teamMember.email} />
											</div>
										</SharkIf>
									</React.Fragment>
								);
							})}
					</div>
				</div>
			</React.Fragment>
		);

		{
			/* Render one column for each date */
		}
		while (loop <= showTo) {
			loop = new Date(newDate);
			counter++;

			newMatrix.push(
				<React.Fragment key={moment(loop).format("DD.MM.Y")}>
					<div className={stylelist("col px-0 text-center ", [counter % 2 != 0, "slot-body-odd"], [counter % 2 == 0, "slot-body-even"], [moment(loop).isSame(new Date(), "days"), "col-today"])}>
						{/* column-head, the date */}
						<div className={stylelist([counter % 2 != 0, "slot-header-odd"], [counter % 2 == 0, "slot-header-even"], " pb-0 pt-3 mb-3 pb-3 slot-header")}>
							{getDayName(loop.getDay())}
							<br />
							<small>{moment(loop).format("DD.MM.Y")}</small>
						</div>
						{/* column-head, the date */}

						{/* Now iterate through each user */}
						{Object.values(Team)
						.filter((Member) => Member.hasSlots)
						.filter((Member) => ((Member.status == "active" && !showDisabledMembers) || showDisabledMembers))
						.filter((Member) => Member.group == UserSettings["currentGroup"] || UserSettings["currentGroup"] == "")
						.map((teamMember) => {
							return (
								<React.Fragment key={teamMember.userName + "-" + getSlotName(loop, teamMember.userName, "1")}>
									{teamMember.hasSlots && (teamMember.group == UserSettings["currentGroup"] || UserSettings["currentGroup"] == "") && (
										<div className={stylelist("slotfield-full p-3", [oofUserThisWeek[teamMember.userName] == 10, "slot-muted"])} key={teamMember.userName + "-" + getSlotName(loop, teamMember.userName, "1")}>
											<div className="container container no-gutters m-0 p-0">
												<div className="row">
													<div className={stylelist([(slotMergeCandidates.indexOf(getSlotName(loop, teamMember.userName, "1")) >= 0 && slotMergeEnabled), "col-12"],
																				[(slotMergeCandidates.indexOf(getSlotName(loop, teamMember.userName, "1")) < 0 && slotMergeEnabled), "col-6"],
													 							"col-6 text-center d-flex justify-content-center align-items-center")}>
														
														<SlotTitle clipboardSlot={clipboardSlot} setClipboard={addToClipboard} clipboardMode={clipboardMode} animate={showAnimation && !clipboardSlot} filterWord={currentFilterWord} slotId={getSlotName(loop, teamMember.userName, "1")} stealthMode={stealthMode} controlMode={controlMode} deleteMergedSlot={(slotMergeCandidates.indexOf(getSlotName(loop, teamMember.userName, "1")) >= 0 && slotMergeEnabled)} mergedSlotId={getSlotName(loop, teamMember.userName, "2")} />
													</div>

													{(slotMergeCandidates.indexOf(getSlotName(loop, teamMember.userName, "1")) < 0 || !slotMergeEnabled) && 
														<div className="col-6 text-center d-flex justify-content-center align-items-center">
															<SlotTitle clipboardSlot={clipboardSlot} setClipboard={addToClipboard} clipboardMode={clipboardMode} filterWord={currentFilterWord} animate={showAnimation && !clipboardSlot} slotId={getSlotName(loop, teamMember.userName, "2")} stealthMode={stealthMode} controlMode={controlMode} />
														</div>
													}
												</div>
											</div>
										</div>
									)}
								</React.Fragment>
							);
						})}

						{/* column-bottom, the date again */}
						<div className="slotfield-full p-3">
							<div className="container container no-gutters m-0 p-0">
								<div className="row">
									<div className={stylelist("ultra-small", "text-muted")}>
										{getDayName(loop.getDay())}, {moment(loop).format("DD.MM.Y")}
									</div>
								</div>
							</div>
						</div>
						{/* column-bottom, the date again */}
					</div>
				</React.Fragment>
			);

			newDate = loop.setDate(loop.getDate() + 1);
		}

		setMatrix(newMatrix);
	}, [slotMergeEnabled,showFrom, showTo, Team, clipboardSlot, currentGroup, currentFilterWord, stealthMode, controlMode, oofUserThisWeek, showDisabledMembers]);

	return (
		<>
			<section className="toolbar">

					<div className="row">
						<div className="col-auto">
							<div
								onClick={() => {
									jumpToCurrentWeek();
								}}
								className="ms-3 btn btn-sm rounded border">
								KW {getNumberWithLeadingZero(displayedCalendarWeek)}
							</div>
						</div>

						<div className="col-auto text-center">
							<button
								className="btn btn-primary"
								onClick={() => {
									nextOrPrevWeek("prev");
								}}>
								<span className="icon-backward2"></span>
							</button>
							&nbsp;&nbsp;
							<button
								className="btn btn-primary"
								onClick={() => {
									nextOrPrevWeek("next");
								}}>
								<span className="icon-forward3"></span>
							</button>
						</div>

						<div className="col-auto text-center">
							<>
								<div
									onClick={() => {
										dispatch(set_current_group(""));
									}}
									className={stylelist("btn btn-sm rounded-0 rounded-start border-start border-top border-bottom", [UserSettings["currentGroup"] == "", "btn-primary"], [!UserSettings["currentGroup"] == "", ""])}>
									Alle
								</div>

								<div
									onClick={() => {
										dispatch(set_current_group("Team 1"));
									}}
									className={stylelist("btn btn-sm rounded-0 rounded-start border-top border-bottom", [UserSettings["currentGroup"] == "Team 1", "btn-primary"], [!UserSettings["currentGroup"] == "Team 1", ""])}>
									Team 1
								</div>

								<div
									onClick={() => {
										dispatch(set_current_group("Team 2"));
									}}
									className={stylelist("btn btn-sm rounded-0 rounded-end border-end border-top border-bottom", [UserSettings["currentGroup"] == "Team 2", "btn-primary"], [!UserSettings["currentGroup"] == "Team 2", ""])}>
									Team 2
								</div>
							</>
						</div>

						
							{false && 
								<>
									<div className="col-auto text-center">
									<div className="btn btn-sm rounded border">Kein Slot in der Zwischenablage</div>
									</div>
								</>
							}

							{clipboardSlot && (
								<>
									<div className="col-auto text-center">
									<small
										className="cursor-pointer btn btn-primary btn-sm"
										onClick={() => {
											setClipboardSlot(false);
											dispatch(enable_sync());
										}}>
										Zwischenablage beenden
									</small>
									</div>
								</>
							)}
					

						<div className="col-auto text-center">
							<input
								placeholder="KW filtern"
								className="form-contrl"
								value={currentFilterWord}
								onChange={(e) => {
									setCurrentFilterWord(e.target.value);
								}}
							/>
							{currentFilterWord != "" && (
								<>
									&nbsp;
									<div
										className="btn btn-sm rounded border"
										onClick={() => {
											setCurrentFilterWord("");
										}}>
										Zurücksetzen
									</div>
								</>
							)}
						</div>

						<div className="col-auto text-center">
							<div
								onClick={() => {
									setShowDisabledMembers(!showDisabledMembers);
								}}
								className={stylelist("btn btn-sm rounded border", [showDisabledMembers, "btn-primary"])}>
									{showDisabledMembers &&
										<>
											Alle Accounts
										</>
									}

									{!showDisabledMembers &&
										<>
											Aktive Accounts
										</>
									}
							</div>
						</div>

						<div className="col-auto text-center">
							<div
								onClick={() => {
									setSlotMergeEnabled(!slotMergeEnabled);
								}}
								className={stylelist("btn btn-sm rounded border", [!slotMergeEnabled, "btn-primary"])}>
									{slotMergeEnabled &&
										<>
											Slots zusammenführen
										</>
									}

									{!slotMergeEnabled &&
										<>
											Slots nicht zusammenführen
										</>
									}
							</div>
						</div>
						<div className="col-auto text-center">
							<a href="https://wiki.shared-01.uo-cloud.de/books/aufwande-und-buchungen/page/aufwandslisten" target="_BLANK" className="btn btn-sm rounded border">Aufwandslisten</a>
						</div>
					</div>
			</section>

			<div
				className="container-fluid mb-5 matrix"
				tabIndex="0"
				onKeyUp={(e) => {
					handleKeypress(e, "up");
				}}
				onKeyDown={(e) => {
					handleKeypress(e, "down");
				}}>
				<div className="row">	
						<>{matrix}</>
					</div>
			</div>
		</>
	);
};

export default SlotCalendar;
