import React from "react";
import { useSelector } from "react-redux";
import uoLogo from "../../assets/uo_logo.png";
import uoLogoPreview from "../../assets/uo_logo_ng.png";
import { useDispatch } from "react-redux";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import SyncComponent from "../Sync/SyncComponent";
import stylelist from "stylelist";
import SharkIf from "../Util/IfCondition";
import { enable_sync, set_sync_status } from "../../actions/Sync";

import { Toaster } from "react-hot-toast";
import { disable_beta, enable_beta } from "../../actions/User";

const Menu = (props) => {
    // Get UserSettings from State
    const UserSettings = useSelector((state) => state.User);
    const SyncInfo = useSelector((state) => state.Sync);
    const dispatch = useDispatch();

    const toggleBeta = (e) => {
        if (e.target.checked) {
            dispatch(enable_beta());
        } else {
            dispatch(disable_beta());
        }
    }

    return (
        <>
            <div className="container-fluid bg-light">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <Link className="navbar-brand me-5" to="/">
                        {UserSettings.betaEnabled &&
                            <>
                                <img src={uoLogoPreview} className="logo" />
                            </>
                        }

                        {!UserSettings.betaEnabled &&
                            <>
                                <img src={uoLogo} className="logo" />
                            </>
                        }
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse pl-5" id="navbarSupportedContent">

                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                            {SyncInfo.loginStatus == "loggedIn" &&
                                <>
                                    <li className="nav-item me-4">
                                        <Link to="/dashboard" className="nav-link text-primary">Dashboard</Link>
                                    </li>

                                    <li className="nav-item me-4">
                                        <Link to="/calendar" className="nav-link text-primary">Kalender</Link>
                                    </li>

                                    <li className="nav-item me-4">
                                        <Link to="/team" className="nav-link text-primary">Team</Link>
                                    </li>

                                    {UserSettings.betaEnabled &&
                                        <>
                                            <li className="nav-item me-4">
                                                <Link to="/expenses" className="nav-link text-primary">Aufwände</Link>
                                            </li>
                                        </>
                                    }

                                    <li className="nav-item me-4 ms-4">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" onChange={(e) => { toggleBeta(e) }} style={{ marginTop: "14px" }} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                            <Link to="/ng" className="nav-link text-primary">NG-Features</Link>
                                        </div>
                                    </li>
                                </>
                            }

                        </ul>

                        <div className="d-flex text-light">

                            {SyncInfo.loginStatus == "loggedIn" &&
                                <>
                                    <div className="text-light">

                                        <SharkIf animate={true} show={true} animation="fade" animationDelay={500}>
                                            <div
                                                onClick={() => { dispatch(enable_sync()); dispatch(set_sync_status("asap_full")) }}
                                                className={
                                                    stylelist(
                                                        "avatar-container",
                                                        [(SyncInfo.syncStatus == "working" && SyncInfo.enableSync == true), "active"],
                                                        [(SyncInfo.syncStatus == "idle" && SyncInfo.enableSync == true), "active"],
                                                        [(SyncInfo.syncStatus == "asap" && SyncInfo.enableSync == true), "active"],
                                                        [SyncInfo.enableSync == false, "notactive"]
                                                    )}>
                                                <Gravatar className={stylelist("avatar", [SyncInfo.loginStatus == "offline", "offline"])} size={30} email={UserSettings.email} />
                                                <div className={stylelist([SyncInfo.syncStatus == "working", "spin-animation"], "status", "cursor-pointer", "rotate-center", "d-flex", "justify-content-center", "d-flex align-items-center")}>

                                                    {(SyncInfo.syncStatus == "idle" && SyncInfo.enableSync == true) &&
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                        </svg>
                                                    }

                                                    {(SyncInfo.syncStatus == "working" && SyncInfo.enableSync == true) &&
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                                            <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                                        </svg>
                                                    }

                                                    {((SyncInfo.syncStatus == "asap" || SyncInfo.syncStatus == "asap_full") && SyncInfo.enableSync == true) &&
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z" />
                                                        </svg>
                                                    }

                                                    {SyncInfo.enableSync == false &&
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z" />
                                                        </svg>
                                                    }
                                                </div>
                                            </div>
                                        </SharkIf>
                                    </div>
                                </>
                            }

                            <div className="toast-wrapper">
                                <Toaster
                                    position="top-center"
                                    reverseOrder={false}
                                    toastOptions={{
                                        className: '',
                                        style: {
                                            marginTop: "15px"
                                        },
                                    }}
                                />
                            </div>

                            <SyncComponent />


                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Menu;
