import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import SyncConstants from "../../constants/SyncConstants";
import { copy, getSlotDate } from "../../libs/SlotUtils";
import ExpenseEntryList from "./ExpenseEntryList";
import stylelist from "stylelist";
import { set_sync_status } from "../../actions/Sync";
import NewProject from "./NewProject";
import { getFriday, getMonday } from "../../libs/CalendarWeek";
import MinutesToTime from "./MinutesToTime";

const ExpenseProject = (props) => {

    const UserSettings = useSelector((state) => state.User);
    const dispatch = useDispatch();
    const Team = useSelector((state) => state.Team);

    // All server data (Expense Project Data)
    const [serverData, setServerData] = useState({});

    // Toggles if child projects are shown (Expanded)
    const [showChildren, setShowChildren] = useState(false);

    // Toggles if booked expense entries are shown (recursive for all sub-projects)
    const [showExpenseEntries, setShowExpenseEntries] = useState(false);

    // Filter-value for "Date from"
    const [filterDateFrom, setFilterDateFrom] = useState(props.filterDateFrom);

    // Filter-value for "Date to"
    const [filterDateTo, setFilterDateTo] = useState(props.filterDateTo);

    // Filter-value for "Person"
    const [filterPerson, setFilterPerson] = useState(props.filterDatePerson);

    const [projectEditMode, setProjectEditMode] = useState(false);

    const [separateByDays, setSeparateByDays] = useState(false);

    const [showNewProject, setShowNewProject] = useState(false);

    const [progressMinutes, setProgressMinutes] = useState(null);

    const [progressPercentage, setProgressPercentage] = useState(null);

    const [showEditProjectId, setShowEditProjectId] = useState(false);

    const [expandView, setExpandView] = useState(false);


    // Run after initializing
    const init = useEffect(() => {
        // Load all child projects from the server
        loadFromServer();

        // Dispatch a global sync (to make sure we have team members etc.)
        dispatch(set_sync_status("asap_full"));

        // Show children if we are on level 1 or 2, so user dont have to expand it manually
        if (props.level == 1) {
            setShowChildren(true);
        }
    }, []);

    useEffect(() => {
        if (props.level > 0) {
            setProjectEditMode(props.projectEditMode);
            setSeparateByDays(props.separateByDays);
        }
    }, [props.projectEditMode, props.separateByDays]);

    const updateProgressMinutes = () => {
        axios({
            method: "get",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntries/sum",
            params: {
                project_id: props.expenseProjectId
            },
            responseType: 'json'
        }).then((response) => {

            let total = response.data.calculation.total;
            if (total > 0) {
                setProgressMinutes(total);
                setProgressPercentage((100 / serverData.parent[0].maxminutes * response.data.calculation.total));
            } else {
                setProgressMinutes(0);
                setProgressPercentage(0);
            }

        }).catch(response => { });
    }

    // Authentication-Object @todo: Zentralisieren
    const getAuthObject = () => {
        return {
            username: UserSettings.userName,
            password: UserSettings.syncToken
        }
    }

    const filterToCurrentWeek = () => {
        setFilterDateFrom(getSlotDate(getMonday(new Date()).toDate()));
        setFilterDateTo(getSlotDate(getFriday(new Date()).toDate()));
    }

    // Set filter-values to inherited props
    useEffect(e => {
        setFilterDateFrom(props.filterDateFrom);
        setFilterDateTo(props.filterDateTo);
        setFilterPerson(props.filterPerson);
    }, [props.filterDateFrom, props.filterDateTo, props.filterPerson])

    // Get all expenseProjects by this ID from the Server
    // @todo: Add error handling and so on
    const loadFromServer = () => {
        axios({
            method: "get",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseProjects/byParent",
            params: {
                parent: props.expenseProjectId
            },
            responseType: 'json'
        }).then((response) => {
            setServerData(response.data);
            updateProgressMinutes();
        }).catch(response => { });
    }

    return <>

        {/* Start: The first level of our tree. We will render some global controls here */}
        {(props.level == 1 && !props.selectMode) &&
            <>

                <div class="d-flex justify-content-between">
                    <h1>Arbeitsaufwände</h1>

                    <div>

                        <div class="form-check form-switch pt-3">
                            <input class="form-check-input" type="checkbox" value={projectEditMode} onChange={e => { setProjectEditMode(e.target.checked) }} />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Projektstruktur editieren</label>
                        </div>

                        <div class="form-check form-switch pt-3">
                            <input class="form-check-input" type="checkbox" value={separateByDays} onChange={e => { setSeparateByDays(e.target.checked) }} />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Nach Tagen separieren</label>
                        </div>
                    </div>

                </div>

                <h4 className="mt-3">Presets</h4>
                <div className="filter container ms-0 ps-0">
                    <div className="row">
                        {/* Start Filter: Date from */}
                        <div className="mb-3 col ">
                            <div className="btn btn-secondary btn-sm me-1" onClick={() => { setFilterPerson(UserSettings.userName) }}>Meine Aufwände</div>
                            <div className="btn btn-secondary btn-sm me-1" onClick={filterToCurrentWeek}>Aktuelle KW</div>
                        </div>
                    </div>
                </div>

                <h4 className="mt-3">Filter</h4>
                {/* Start: Filter by from- and to-date and the person */}
                <div className="filter container ms-0 ps-0">
                    <div className="row">
                        {/* Start Filter: Date from */}
                        <div className="mb-3 col ">
                            <label for="exampleFormControlInput1" className="form-label">Datum (von)</label>
                            <input type="date" className="form-control" value={filterDateFrom} onChange={e => { setFilterDateFrom(e.target.value) }} />
                        </div>
                        {/* End Filter: Date from */}

                        {/* Start Filter: Date to */}
                        <div className="mb-3 col ">
                            <label for="exampleFormControlInput1" className="form-label">Datum (bis)</label>
                            <input type="date" className="form-control" value={filterDateTo} onChange={e => { setFilterDateTo(e.target.value) }} />
                        </div>
                        {/* End Filter: Date to */}

                        {/* Start Filter: Person */}
                        <div className="mb-3 col ">
                            <label for="exampleFormControlInput1" className="form-label">Person</label>
                            <select value={filterPerson} className="form-select" onChange={e => { setFilterPerson(e.target.value) }}>
                                <option value="">Alle</option>

                                {/* Start: Iterate through all team members who has slots */}
                                {Object.values(Team).sort((a, b) => { return a.realName > b.realName }).map(m => {
                                    return <>
                                        {(m.status == "active") &&
                                            <option value={m.userName} className="form-control" >{m.realName} </option>
                                        }
                                    </>
                                })}
                                {/* End: Iterate through all team members who has slots */}
                            </select>
                        </div>
                        {/* End Filter: Person */}

                    </div>
                </div>
                {/* End: Filter by from- and to-date and the person */}
                <h4 className="mt-3">Daten</h4>

            </>

        }
        {/* End: The first level of our tree. We will render some global controls here */}


        <div className="container pe-0 m-0 p-0 mt-2">
            <div className="ms-3">
                {(serverData.children !== undefined && serverData.parent !== undefined) &&
                    <>
                        <div className="d-flex justify-content-between bg-on-hover pt-2">
                            <h3 className={stylelist(
                                [props.level == 1, "h6"],
                                [props.level == 2, "h6"],
                                [props.level == 3, "h6"],
                                [props.level > 3, "h6"],
                            )}>

                                {/* Start: Collapse and Expand Tree */}
                                {(showChildren && (serverData.children.length > 0)) &&
                                    <span className="text-primary cursor-pointer" onClick={e => { setShowChildren(false); }}><i className="bi bi-dash"></i></span>
                                }

                                {(!showChildren && (serverData.children.length > 0)) &&
                                    <span className="text-primary cursor-pointer" onClick={e => { setShowChildren(true); }}><i className="bi bi-plus"></i></span>
                                }
                                {/* End: Collapse and Expand Tree */}

                                &nbsp;

                                <span className={stylelist([serverData.children.length == 0, "ps-3"])}>

                                    {/* Start: Render title. When in select mode, we will call the given callback function.
                                    otherwise, we will just expand all children-items */}
                                    {(!props.selectMode) &&
                                        <span onClick={e => { setShowChildren(!showChildren) }} className="cursor-pointer">{serverData.parent[0].title}</span>
                                    }

                                    {(props.selectMode) &&
                                        <>
                                            {(serverData.parent[0].bookable == true) &&
                                                <span onClick={e => { props.onSelect(serverData.parent[0]); }} className="cursor-pointer">{serverData.parent[0].title}</span>
                                            }

                                            {(serverData.parent[0].bookable == false) &&
                                                <span className="text-muted cursor-na">{serverData.parent[0].title}</span>
                                            }
                                        </>
                                    }
                                    {/* End: Render title */}

                                </span>
                                &nbsp;
                            </h3>

                            <div>
                                <div className="row g-0">
                                    <div className="col-auto">
                                        {Boolean(serverData.parent[0].maxminutes > 0) &&
                                            <>
                                                <progress value={progressMinutes} className="pt-2" max={(serverData.parent[0].maxminutes)} />
                                            </>
                                        }
                                    </div>

                                    <div className="col-auto">
                                        {!projectEditMode &&
                                            <>

                                                {/* Start: Offer to expand Expenses in Detail view */}
                                                {(!showExpenseEntries && !props.selectMode) > 0 &&
                                                    <span className="text-primary cursor-pointer h6 me-2" onClick={e => setShowExpenseEntries(!showExpenseEntries)}>&nbsp; Aufwände</span>
                                                }

                                                {(showExpenseEntries && !props.selectMode) &&
                                                    <span className="text-primary cursor-pointer h6 me-2" onClick={e => setShowExpenseEntries(!showExpenseEntries)}>&nbsp; Schließen</span>
                                                }
                                                {/* End: Offer to expand Expenses in Detail view */}

                                            </>
                                        }

                                        {projectEditMode &&
                                            <>
                                                {(!showNewProject && showEditProjectId < 1) &&
                                                    <>
                                                        <span className="text-primary cursor-pointer h6" onClick={e => setShowNewProject(true)}>&nbsp; Neu</span>
                                                        <span className="text-primary cursor-pointer h6" onClick={e => setShowEditProjectId(serverData.parent[0].id)}>&nbsp; Bearbeiten</span>
                                                    </>
                                                }

                                                {showEditProjectId > 0 &&
                                                    <>
                                                        <span className="text-primary cursor-pointer h6" onClick={e => setShowEditProjectId(false)}>&nbsp; Schließen</span>

                                                    </>
                                                }

                                                {showNewProject &&
                                                    <>
                                                        <span className="text-primary cursor-pointer h6" onClick={e => setShowNewProject(!showNewProject)}>&nbsp; Schließen</span>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {showEditProjectId > 0 &&
                            <NewProject editId={showEditProjectId} afterSubmit={() => { loadFromServer(); setShowEditProjectId(false) }} />
                        }

                        {(showNewProject && projectEditMode) &&
                            <>
                                <NewProject projectParentId={serverData.parent[0].id} afterSubmit={() => { loadFromServer(); setShowNewProject(false) }} />
                            </>
                        }

                        {showChildren && !showExpenseEntries &&
                            <>

                                {/* Start: Show all child-elements */}
                                {Object.values(serverData.children).map(iteration => {
                                    return <React.Fragment key={iteration.id}>
                                        <ExpenseProject
                                            expenseProjectId={iteration.id}
                                            level={parseInt(props.level) + 1}
                                            filterDateFrom={filterDateFrom}
                                            filterDateTo={filterDateTo}
                                            filterPerson={filterPerson}
                                            selectMode={props.selectMode}
                                            onSelect={props.onSelect}
                                            projectEditMode={projectEditMode}
                                            separateByDays={separateByDays}
                                        />
                                    </React.Fragment>
                                })}
                                {/* End: Show all child-elements */}

                            </>
                        }

                        {/* Start: Show all Expense Entries ("Aufwände") to this project */}
                        {showExpenseEntries &&
                            <>
                                <div className={stylelist(
                                    "border mt-2 mb-2 pt-3 pb-3 bg-light p-3 ps-4 pe-4",
                                    [expandView, "expand-absolute ps-5 pe-5"]
                                )}>

                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="mt-3 mb-4">{serverData.parent[0].title} inkl. Unterprojekte

                                                <span className="text-muted">
                                                    &nbsp; ({Boolean(progressMinutes > 0) &&
                                                        <>
                                                            <MinutesToTime minutes={progressMinutes} />
                                                        </>
                                                    }

                                                    {Boolean((serverData.parent[0].maxminutes) > 0) &&
                                                        <>
                                                            &nbsp;von <MinutesToTime minutes={(serverData.parent[0].maxminutes)} />
                                                        </>
                                                    })
                                                </span>
                                            </h5>
                                        </div>
                                        <div>
                                        <h5 className="mt-3 mb-4 text-primary">
                                                {expandView && 
                                                    <>
                                                        <i onClick={() => {setExpandView(false)}} class="bi cursor-pointer bi-arrows-collapse-vertical"></i>
                                                    </>
                                                }

                                                {!expandView && 
                                                    <>

                                                        <i onClick={() => {setExpandView(true)}} class="bi cursor-pointer bi-arrows-expand-vertical"></i>
                                                    </>
                                                }
                                        </h5>
                                        </div>
                                    </div>


                                    <div>
                                        <ExpenseEntryList
                                            project={serverData.parent[0].id}
                                            filterDateFrom={filterDateFrom}
                                            filterDateTo={filterDateTo}
                                            filterPerson={filterPerson}
                                            separateByDays={separateByDays}
                                            expandView={expandView}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        {/* End: Show all Expense Entries ("Aufwände") to this project */}
                    </>
                }
            </div>
        </div>
    </>
}

export default ExpenseProject;