import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'moment/locale/de';
import stylelist from "stylelist";
import { useHistory, useParams, Link } from "react-router-dom";
import { add_new_slot, delete_slot, update_slot } from "../../actions/Slots";
import { copy, getSlotDate, getSlotMetaFromId } from "../../libs/SlotUtils";
import UserSelector from "./UserSelector";
import axios from "axios";
import Expenses from "../Expenses/ExpenseProject";
import SyncConstants from "../../constants/SyncConstants";
import { set_notification } from "../../actions/Sync";
import { Activities } from "../../constants/ExpenseConstants";


const ExpenseMask = (props) => {

    const [selectedProject, setSelectedProject] = useState(null);
    const UserSettings = useSelector((state) => state.User);
    const Team  = useSelector((state) => state.Team);

    const [title, setTitle] = useState("");
    const [durationHours, setDurationHours] = useState("0");
    const [durationMinutes, setDurationMinutes] = useState("0");
    const [expenseDate, setExpenseDate] = useState (getSlotDate(new Date));
    const [activity, setActivity] = useState(Team[UserSettings.userName].defaultActivity);

    console.log();

    const dispatch = useDispatch();
    
    // Authentication-Object @todo: Zentralisieren
    const getAuthObject = () => {
        return {
            username: UserSettings.userName,
            password: UserSettings.syncToken
        }
    }

    useEffect(() => {
        if(props.slotId) {
            setExpenseDate(getSlotMetaFromId(props.slotId).slotDate);
        }else {
            setExpenseDate(getSlotDate(new Date()));
            console.log("Set Expense Date ");
        }
    }, []);

    const sendToServer = () => {
        axios({
            method: "post",
            auth:getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntry",
            responseType: 'json',
            data: {
                title : title,
                durationHours : durationHours,
                durationMinutes : durationMinutes,
                expenseProjectId : selectedProject.id,
                person : UserSettings.userName,
                slotId : props.slotId,
                expenseDate : expenseDate,
                entryDate : getSlotDate(new Date()),
                activity : activity
            }
        }).then((response) => {
            setTitle("");
            setDurationHours(0);
            setDurationMinutes(0);
            setExpenseDate(getSlotDate(new Date()));
            setSelectedProject(null);
            dispatch(set_notification("Erfolgreich gebucht", "success"));
            props.afterSubmit();

        }).catch(response => {});
    }

    return <>
        <form>
            <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <h6>Tätigkeit</h6>
                            <input type="text" className="form-control" value={title} onChange={e => {setTitle(e.target.value)}} />
                            <span className="small text-muted ms-1">Beschreibung der durchgeführten Tätigkeit.</span>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="form-group">
                            <h6>Datum</h6>
                            <input type="date" className="form-control" value={expenseDate} onChange={e => {setExpenseDate(e.target.value)}} />
                            <span className="small text-muted ms-1">Datum der Buchung.</span>
                        </div>
                    </div>
                </div>

            <div className="row">
                <div className="col-6">
                    <div className="form-group mt-4">
                        <h6>Dauer</h6>
                    
                        <div className="row">
                            <div className="col-5">
                                <input type="text" placeholder="Stunden" className="form-control" value={durationHours} onChange={e => {setDurationHours(e.target.value)}} />
                                <span className="small text-muted ms-1">Stunden</span>
                            </div>
                            <div className="col-5">
                                <input type="text" placeholder="Minuten" className="form-control" value={durationMinutes} onChange={e => {setDurationMinutes(e.target.value)}}/>
                                <span className="small text-muted ms-1">Minuten</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-group mt-4">
                        <h6>Tätigkeit</h6>
                            <select value={activity} className="form-select" onChange={e => { setActivity(e.target.value) }}>
                                {Object.keys(Activities).map(key => {
                                    return <>
                                        <option value={key}>{Activities[key]}</option>
                                    </>
                                })}

                            </select>
                    </div>
                </div>
            </div>

            <div className="form-group mt-4">
            <h6>Projekt</h6>

                {selectedProject !== null &&
                    <div className="small">
                        {selectedProject.title} &nbsp; (<span className="text-danger cursor-pointer" onClick={() => {setSelectedProject(null)}}>Löschen</span>)
                    </div>
                }

                {selectedProject == null &&
                    <>
                        <Expenses
                            onProjectSelect={p => { debug(p) }}
                            expenseProjectId="1"
                            level="1"
                            filterDateFrom={false}
                            filterDateTo={false}
                            filterPerson={false}
                            selectMode={true}
                            onSelect={(e) => { setSelectedProject(e) }}
                        />
                    </>
                }
            </div>

            <div className="form-group mt-3">
                {(title != "" && selectedProject != null && durationHours != "" && durationMinutes != "") && 
                    <div className="btn btn-secondary btn-sm" onClick={() => {sendToServer();}}>Senden</div>
                }

                {!(title != "" && selectedProject != null && durationHours != "" && durationMinutes != "") && 
                    <>
                        <div className="btn btn-sm btn-secondary small muted cursor-na">Alle Felder zum Absenden ausfüllen.</div>
                    </>
                }
            </div>
        </form>
    </>

}

export default ExpenseMask;