import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { set_sync_status } from "../../actions/Sync";
import { getSlotName } from "../../libs/SlotUtils";
import AddSlot from "../Slots/AddSlot";
import Expenses from "../Expenses/ExpenseProject"
import axios from "axios";
import SyncConstants from "../../constants/SyncConstants";

const NewProject = (props) => {
    const UserSettings = useSelector((state) => state.User);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [comment, setComment] = useState("");
    const [bookable, setBookable] = useState(false);
    const [durationHours, setDurationHours] = useState("0");
    const [durationMinutes, setDurationMinutes] = useState("0");
    const [id, setId] = useState(false);

    const getAuthObject = () => {
        return {
            username: UserSettings.userName,
            password: UserSettings.syncToken
        }
    }

    const sendToServer = () => {
        axios({
            method: "post",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseProject",
            responseType: 'json',
            data: {
                title: title,
                id: id,
                description: description,
                comment: comment,
                bookable: bookable,
                parentId: props.projectParentId,
                maxminutes: parseInt(durationHours * 60) + parseInt(durationMinutes)
            }
        }).then((response) => {
            props.afterSubmit();

        }).catch(response => { });
    }

    const openIdFromServer = (id) => {
        axios({
            method: "get",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseProject",
            responseType: 'json',
            params: {
                id: id
            }
        }).then((response) => {
            setTitle(response.data.title);
            setDescription(response.data.description);
            setComment(response.data.notes);
            setBookable(response.data.bookable > 0 ? true : false);
            setId(response.data.id);
            if (response.data.maxminutes > 0) {
                setDurationHours(Math.floor(response.data.maxminutes / 60));
                setDurationMinutes(response.data.maxminutes - (Math.floor(response.data.maxminutes / 60) * 60));
            }

        }).catch(response => { });
    }

    useEffect(() => {

        if (props.editId > 0) {
            console.log("Edit ID found, we will edit an existing project");
            openIdFromServer(props.editId);
        } else {
            console.log("No edit id");
        }
    }, []);

    return <>
        <div>
            <form className="bg-light py-4 px-4 border">

                <h5 className="mb-3">Projekt anlegen oder bearbeiten</h5>

                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <h6>Titel</h6>
                            <input type="text" className="form-control" value={title} onChange={e => { setTitle(e.target.value) }} />
                            <span className="small text-muted ms-1">Titel</span>
                        </div>
                    </div>
                </div>

                <div className="row  mt-4">
                    <div className="col-6">
                        <div className="form-group">
                            <h6>Beschreibung</h6>
                            <input type="text" className="form-control" value={description} onChange={e => { setDescription(e.target.value) }} />
                            <span className="small text-muted ms-1">Sprechende Bezeichnung für die Kostenstelle</span>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6">
                        <div className="form-group">
                            <h6>Kommentar</h6>
                            <input type="text" className="form-control" value={comment} onChange={e => { setComment(e.target.value) }} />
                            <span className="small text-muted ms-1">Interner Kommentar (Stundensätze, Vereinbarungen, etc.)</span>
                        </div>
                    </div>
                </div>

                <h6 className=" mt-4">Maximale Stunden (optional)</h6>
                <div className="row">
                    <div className="col-3">
                        <input type="text" placeholder="Stunden" className="form-control" value={durationHours} onChange={e => { setDurationHours(e.target.value) }} />
                        <span className="small text-muted ms-1">Stunden</span>
                    </div>
                    <div className="col-3">
                        <input type="text" placeholder="Minuten" className="form-control" value={durationMinutes} onChange={e => { setDurationMinutes(e.target.value) }} />
                        <span className="small text-muted ms-1">Minuten</span>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6">
                        <div className="form-group">
                            <h6>Bookable</h6>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" checked={bookable} onChange={e => { setBookable(e.target.checked) }} />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Kann Buchungen erhalten?</label>
                            </div>

                            <span className="small text-muted ms-1">Gibt an, ob auf dieser Stelle direkt Buchungen getätigt werden können.</span>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">

                    <div className="col-3">
                        {(title != "" && props.editId === undefined) &&
                            <button type="button" className="btn btn-primary" onClick={e => { sendToServer() }}>Erstellen</button>
                        }

                        {(title != "" && props.editId !== undefined) &&
                            <button type="button" className="btn btn-primary" onClick={e => { sendToServer() }}>Speichern</button>
                        }
                    </div>
                </div>
            </form>

        </div>
    </>
}

export default NewProject