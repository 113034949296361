import axios from "axios";
import md5 from "md5";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_all_weclapp_orders } from "../../actions/Glados";
import { add_multiple_slots, empty_slot_cache } from "../../actions/Slots";
import { set_login_status, set_notification, set_sync_status } from "../../actions/Sync";
import { add_or_update_user } from "../../actions/Team";
import { update_user_profile } from "../../actions/User";
import SyncConstants from "../../constants/SyncConstants";

import { toast } from "react-hot-toast";

const SyncComponent = (props) => {
    const UserSettings = useSelector((state) => state.User);
    const SyncInfo = useSelector((state) => state.Sync);
    const Slots = useSelector((state) => state.Slot);
    const [SecondsTimer, setSecondsTimer] = useState();
    const [LastSyncInSeconds, setLastSyncInSeconds] = useState(new Date().getTime() / 1000);

    const dispatch = useDispatch();
    const getAuthObject = () => {
        return {
            username: UserSettings.userName,
            password: UserSettings.syncToken
        }
    }

    /**
     * Load all Team data (only on full sync since they shouldn´t change really often)
     */
    const getTeamData = () => {
        axios({
            method: "get",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "user",
            responseType: 'json'
        }).then((response) => {
            Object.values(response.data).map((value) => {
                dispatch(add_or_update_user(value.data.userName, value.data));

                let hash = md5(value.data.email);
                let string = "https://www.gravatar.com/avatar/" + hash + "?d=mp&r=g&s=50";
                let preloader = new Image();
                preloader.src = string;

            });
        });
    }



    /**
     * Pull down all slots from the server (on every sync)
     */
    const getAllSlots = () => {



    
            axios({
                method: "get",
                auth: getAuthObject(),
                url: SyncConstants["syncBaseURL"] + "getSlotsLazy",
                responseType: 'json',
                params: {
                    from: UserSettings.currentLazyFrom,
                    to: UserSettings.currentLazyTo
                }
            }).then((response) => {
                let newSlots = [];

                Object.values(response.data).map((value) => {
                    try {
                        newSlots.push(value);

                    } catch (e) { }
                });

                dispatch(add_multiple_slots(newSlots));
                dispatch(set_sync_status("idle"));
            }).catch((e) => {
                console.log("Login impossible - wrong credentials!?")
                console.log(e);
            });
        
    }

    /**
     * Get all data from GLADOS.
     * Currently disabled, but will be available within one of the next releases
     */
    const getGlasdosData = () => {
        /* axios({
             method: "get",
             auth: getAuthObject(),
             url: SyncConstants["syncBaseURL"] + "gladosJobs",
             responseType: 'json'
         }).then((response) => {
             dispatch(set_all_weclapp_orders(response.data.rows));
         });*/
    }

    /**
     * Try to login with our credentials
     */
    const runTryLogin = () => {
        axios({
            method: "get",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"],
            responseType: 'json'
        }).then((response) => {

            if (response.data.authenticated) {
                dispatch(set_login_status("loggedIn"));
                history.push("/dashboard");
            }

            dispatch(set_sync_status("idle"));
        }).catch(response => {
            //console.log("Login impossible. This happens really often when typing in the password-field since we try to login you automatically.");
        });
    }

    /**
     * Execute commands when the global sync-state has changed
     */
    useEffect(
        () => {

            getAllSlots();

            // check if sync es enabled and we aren´t already syncing
            if (SyncInfo.enableSync && SyncInfo.syncStatus != "working") {
                //console.log("Starting sync");

                if (Object.values(Slots).length > 1000) {
                    console.log("Cache-limit reached. Clearing.");
                    dispatch(empty_slot_cache());

                }

                if (SyncInfo.syncStatus == "asap") {
                    dispatch(set_sync_status("working"));
                    runTryLogin();

                    if (SyncInfo.enableSync) {
                        getAllSlots();
                    }
                }

                if (SyncInfo.syncStatus == "asap_full") {

                    dispatch(set_sync_status("working"));
                    runTryLogin();
                    getTeamData();

                    if (SyncInfo.enableSync) {
                        dispatch(empty_slot_cache());
                        getAllSlots();
                    }

                    getTeamData();
                    getGlasdosData();
                    dispatch(update_user_profile("slotsSynced", true));
                    dispatch(update_user_profile("teamSynced", true));
                }

                setSecondsTimer((new Date().getTime() / 1000) - LastSyncInSeconds);
                setLastSyncInSeconds(new Date().getTime() / 1000);
            } else {
                //console.log("Skipping Sync because we are already working or its disabled");
            }
        },
        [SyncInfo.syncStatus, SyncInfo.enableSync, UserSettings.currentLazyFrom, UserSettings.currentLazyTo]
    );

    useEffect(() => {

        if (SyncInfo.notification.type == "success") {
            toast.success(SyncInfo.notification.message);
        }

        if (SyncInfo.notification.type == "info") {
            toast.success(SyncInfo.notification.message, { icon: "ℹ️" });
        }

        if (SyncInfo.notification.type == "error") {
            toast.success(SyncInfo.notification.message, { icon: "🛑" });
        }

    }, [SyncInfo.notification.counter]);


    useEffect(() => {
        if (SyncInfo.loginStatus == "loggedIn") {
            dispatch(set_notification("Synchronisierung gestartet", "info"));
        }
    }, [SyncInfo.loginStatus]);

    return <div className="">

    </div>
}

export default SyncComponent;