import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import SyncConstants from "../../constants/SyncConstants";
import { copy } from "../../libs/SlotUtils";
import PersonLabelWithDynamicInfo from "../Team/PersonLabelWithDynamicInfo";
import MinutesToTime from "./MinutesToTime";
import ConfirmButton from "./ConfirmButton";
import moment from "moment";
import stylelist from "stylelist";
import { Activities } from "../../constants/ExpenseConstants";

const ExpenseEntryList = (props) => {

    const UserSettings = useSelector((state) => state.User);
    const [serverData, setServerData] = useState({});
    const [showChildrenIds, setShowChildrenIds] = useState([]);
    const [showFullBreadcrumb, setShowFullBreadcrumb] = useState(false);

    // Beim Starten: Aktionen ausführen
    const init = useEffect(() => {
        loadFromServer();
    }, []);

    useEffect(() => {
        loadFromServer();
    }, [props.filterDateFrom, props.filterDateTo, props.filterPerson]);

    // Authentication-Object @todo: Zentralisieren
    const getAuthObject = () => {
        return {
            username: UserSettings.userName,
            password: UserSettings.syncToken
        }
    }

    const loadFromServer = () => {
        axios({
            method: "get",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntries/byProject",
            params: {
                project: props.project,
                filter: {
                    dateFrom: props.filterDateFrom,
                    dateTo: props.filterDateTo,
                    person: props.filterPerson
                }
            },
            responseType: 'json'
        }).then((response) => {
            setServerData(response.data);

        }).catch(response => { });
    }

    const deleteEntry = (entryId) => {
        axios({
            method: "delete",
            auth: getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntry",
            responseType: 'json',
            data: { entryId: entryId }
        }).then((response) => {
            loadFromServer();

        }).catch(response => {

        });
    }
    
    const afterEdit = () => {
        loadFromServer();
    }

    let currentDate;
    let isNewDate;
    return <>

        {serverData.entries !== undefined &&
            <>

                <div className=" p-0">

                    {Object.values(serverData.entries).length > 0 &&
                        <>
                            <table style={{transition: "500ms"}} className={stylelist(
                                "table table-bordered mb-0 small table-hover",
                                [!props.separateByDays, "table-striped"]

                            )}>
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle">Datum</th>
                                        <th className="align-middle">Person</th>
                                        {props.expandView && <th className="align-middle">Slot</th>}
                                        <th className="align-middle">Kommentar</th>
                                        <th className="text-center align-middle">Aufwand</th>
                                        <th className="align-middle">Tätigkeit u. Projekt
                                        &nbsp;
                                        {!showFullBreadcrumb &&
                                                            <>
                                                                <i onClick={() => { setShowFullBreadcrumb(true); }} class="bi bi-info-circle cursor-pointer" title={"Ausklappen"}></i>
                                                            </>
                                                        }

                                                        {showFullBreadcrumb &&
                                                            <>
                                                                <i onClick={() => { setShowFullBreadcrumb(false); }} class="bi bi-x-circle cursor-pointer"></i>
                                                            </>
                                                        }

                                        </th>
                                        <th className="align-middle">Aktionen</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <>
                                        {currentDate = ""}
                                        {isNewDate = false}

                                        {Object.values(serverData.entries).sort((a, b) => { return b.date.toString().localeCompare(a.date) }).map(iteration => {
                                            if (currentDate != iteration.date) {
                                                isNewDate = true;
                                                currentDate = iteration.date;
                                            } else {
                                                isNewDate = false;
                                            }

                                            return <>


                                                {Boolean(isNewDate && props.separateByDays) &&
                                                    <>
                                                        <tr className="border-top-thick border-bottom-thick bg-tr-white">
                                                            <td colspan="7" className="text-center fw-bold pt-3 pb-3">
                                                                {moment(iteration.date).format("DD.MM.Y")}
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                                <tr
                                                    className={stylelist([isNewDate, ""])}
                                                >
                                                    <td className=" align-middle text-center" style={{ height: "34px" }}>
                                                        {moment(iteration.date).format("DD.MM.Y")}
                                                    </td>
                                                    <td className="align-middle"><PersonLabelWithDynamicInfo name={iteration.person} /></td>
                                                    {props.expandView && <td className="align-middle">{iteration.slot_id}</td>}
                                                    <td className="align-middle">{iteration.comment}</td>
                                                    <td className="align-middle text-center"><MinutesToTime minutes={iteration.minutes} /></td>
                                                    <td className="align-middle " style={{maxWidth:showFullBreadcrumb ? "650px" : "300px"}}>
                                                        {iteration.activity && 
                                                        <>{Activities[iteration.activity]}<br /></>
                                                        }
                                                        {!showFullBreadcrumb &&
                                                            <>
                                                                <span className="mt-1 extra-small-tabletext" >{(iteration.project_title).substr(0, 70)}</span>
                                                            </>
                                                        }

                                                        {showFullBreadcrumb &&
                                                            <>
                                                                <span className="mt-1 extra-small-tabletext" >{iteration.breadcrumb}</span> 
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="align-middle align-middle">
                                                        <ConfirmButton
                                                            label="Löschen"
                                                            labelConfirm="Wirklich?"
                                                            className="text-primary cursor-pointer"
                                                            onConfirm={e => { deleteEntry(iteration.id) }} />
                                                    </td>
                                                </tr>
                                            </>
                                        })}
                                    </>

                                    <tr>
                                        <th className="fw-bold align-middle">Gesamt </th>

                                        <th></th>
                                        {props.expandView && <th></th>}
                                        <th></th>
                                        <th className="align-middle fw-bold text-center"><MinutesToTime minutes={serverData.calculation.total} /></th>
                                        <th></th>
                                        {props.expandView && <th></th>}
                                    </tr>


                                </tbody>
                            </table>
                        </>
                    }

                    {Object.values(serverData.entries).length < 1 &&
                        <>
                            <p>Keine Buchungen mit den gewählten Filtern vorhanden.</p>
                        </>
                    }
                </div>
            </>
        }
    </>
}

export default ExpenseEntryList;