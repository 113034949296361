import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { set_sync_status } from "../../actions/Sync";
import { getSlotName } from "../../libs/SlotUtils";
import AddSlot from "../Slots/AddSlot";
import Expenses from "../Expenses/ExpenseProject"

const ExpensesPage = (props) => {

    const UserSettings = useSelector((state) => state.User);

    return (
        <>
            <div className="container mt-5">

                {UserSettings.betaEnabled &&
                    <>
                        <Expenses
                            onProjectSelect={p => { debug(p) }}
                            expenseProjectId="1"
                            level="1"
                            filterDateFrom={false}
                            filterDateTo={false}
                            filterPerson={false}
                            selectMode={false}
                            onSelect={(e) => { console.log(e) }}
                            projectEditMode={false}
                        />

                    </>
                }

                {!UserSettings.betaEnabled &&
                    <>
                        <h1>Arbeitsaufwände</h1>
                        <p>Diese Funktion ist aktuell noch im internen Test. Aktiviere den NG-Funktionstest, um sie zu nutzen.</p>
                    </>
                }

                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        </>
    );
};

export default ExpensesPage;
